/*==================== TYPOGRAPHY ====================*/
:root {
	--fz-h1: 34px;
	--fz-h2: 24px;
	--fz-h3: 20px;
	--fz-h4: 18px;


	@include desctop-min {
		--fz-h1: 28px;
		--fz-h2: 20px;
		--fz-h3: 18px;
		--fz-h4: 16px;
	}

	@include mobile {
		--fz-h1: 20px;
		--fz-h2: 18px;
		--fz-h3: 16px;
		--fz-h4: 16px;
	}
}

.b-content {
	font: 400 16px/#{(25/16)} var(--fm-1);
	color: #414554;

	h1,
	.h1 {
		font-size: var(--fz-h1);
	}

	h2,
	.h2 {
		font-size: var(--fz-h2);
	}

	h3,
	.h3 {
		font-size: var(--fz-h3);
	}

	h4,
	.h4 {
		font-size: var(--fz-h4);
	}

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4 {
		margin: 1.3em 0 em(29, 20);
		font-weight: 700;
		line-height: em(45, 36);
		color: #19191C;

		&:first-child {
			margin-top: 0;
		}
	}

	ol,
	ul,
	p {
		margin-bottom: em(28, 16);

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul,
	ol {
		padding-left: 1.5em;

		li {
			margin-bottom: 0.4em;
			list-style-type: disc;
		}
	}

	ol li {
		list-style-type: decimal;
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	@include tel {
		font-size: 14px;
		line-height: (20/14);
	}
}