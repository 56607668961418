/*~~~~~~~~~~~~~~~~~~~~~~~~~ COMMON LAYOUT ~~~~~~~~~~~~~~~~~~~~~~~~~*/
.boxes {
    $this: &;

    display: flex;
    width: 100%;

    & > * {
        min-width: 0;

        & + * {
            margin-left: 24px;
        }
    }

    &__main {
        flex-grow: 1;
    }

    &__side {
        flex-shrink: 0;
        width: 484px;

        &:empty {
            display: none;
        }

		&--filters {
			width: 215px;
		}
    }

	@include table {
		flex-direction: column;

		& > * + * {
			margin: 20px 0 0;
		}

		&__side--filters + * {
			margin-top: 0;
		}
	}
}

/* btns */
.btns {
	$this: &;

	display: flex;
	align-items: center;

	& > * {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__list {
		flex-grow: 1;
		@include row-flex;
		--air-x: 20px;
		--air-y: 20px;

		> * {
			@include col;
		}
	}

	&--flex {
		display: flex;
		align-items: center;
	}

	&--wrap {
		--air-x: 28px;
		--air-y: 16px;

		@include row-flex;

		& > * {
			@include col;
			margin-right: 0;
		}
	}

	&--filling {
		&_full {
			#{$this}__list > * {
				flex-grow: 1;
				width: calc(100% - var(--air-x));
			}
		}
	}

	&--direction {
		&_col {
			#{$this}__list {
				flex-direction: column;
			}
		}
	}
}

.items {
	--air-x: 24px;
	--air-y: 24px;

	@include row-flex;

	& > * {
		@include col;
	}
}

/*section*/
.section {
	padding: 80px 0 20px;

	&__title {
		margin-bottom: 50px;
		font-weight: 700;
		font-size: 34px;
	}

	&__btn {
		--h: #{getEm(49, 14)};
		--fz: 14px;
		margin: 50px auto 0;
		padding-left: getEm(42, 14);
		padding-right: getEm(42, 14);
	}

	@include desctop-min {
		padding: 60px 0 20px;

		&__title {
			margin-bottom: 40px;
			font-size: 28px;
		}

		&__btn {
			--height: 60px;
			--fz: 16px;
			margin: 55px auto 0;
		}
	}

	@include table {
		padding: 40px 0 20px;

		&__btn {
			--height: 51px;
			--fz: 12px;
			margin: 50px auto 0;
		}
	}

	@include mobile {
		&__title {
			margin-bottom: 32px;
			font-size: 24px;
		}

		&__btn {
			--fz: 12px;
			--h: 40px;
			margin: 43px auto 0;
		}
	}
}

.col-count-2 {
	column-count: 2;
	column-gap: var(--air-x);

	& > * {
		break-inside: avoid-column;
	}
}

.col-count-sm-1 {
	@include mobile {
		column-count: 1;
	}
}
