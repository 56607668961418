/*~~~~~~~~~~~~~~~~~~~~~~~~~ FOOTER ~~~~~~~~~~~~~~~~~~~~~~~~~*/
.footer {
	position: relative;
	padding-top: 49px;
	font-size: 14px;
	color:#414141;
	background-color: var(--color-bg-main);
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);

	&__body {
		display: grid;
		grid-template-columns: repeat(4, auto);
		justify-content: space-between;
	}

	&__col {
		> * {
			margin-bottom: 22px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__bottom {
		padding: 17px 0 15px;
		font-size: 16px;
		background-color: var(--color-orange);
		color: #fff;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__menu {
		padding-left: 0;
		font-weight: 600;
		text-transform: uppercase;

		li {
			list-style: none;
			margin-bottom: 1.54em;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: inherit;

			&:hover {
				color: var(--color-primary);
				text-decoration: underline;
			}
		}
	}

	&__links {
		> * {
			margin-bottom: 1.4em;
			
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			font-weight: 700;
			text-decoration: underline;
			text-transform: uppercase;
			color: var(--color-primary);

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__logo {
		margin-bottom: 18px;
		max-width: 170px;
	}

	&__bottom {
		margin-top: 54px;
	}

	@include table {
		&__body {
			grid-template-columns: auto auto;
			column-gap: 60px;
			row-gap: 30px;
			justify-content: flex-start;
		}
	}

	@include mobile {
		padding-top: 23px;

		&__body {
			grid-template-columns: 1fr;
			row-gap: 30px;
		}

		&__logo {
			margin-bottom: 13px;
		}

		&__bottom {
			margin-top: 34px;
			padding: 20px 0 19px;
			font-size: 14px;
		}
	}

	@include tel {
		font-size: 12px;
	}
}
