/*~~~~~~~~~~~~~~~~~~~~~~~~~ HEADER ~~~~~~~~~~~~~~~~~~~~~~~~~*/
.header {
	position: sticky;
	top: 0;
	z-index: 110;
	font-size: 18px;
	background-color: #fff;

	&__inner {
		position: relative;
		z-index: 50;
	}

	&__top {
		padding: 18px 0 19px;

		&-container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&::before,
			&::after {
				display: none;
			}
		}
	}

	&__bottom {
		color: #fff;
		background-color: var(--color-primary);

		&-container {
			display: flex;
			align-items: center;

			&::before,
			&::after {
				display: none;
			}
		}

		.menu {
			&__toggle {
				--color-tone: #fff;
			}
		}
	}

	&__burger {
		&-content {
			display: none;
		}
	}

	.msgrs,
	.firm-info {
		font-size: 16px;
	}

	.msgrs__link {
		svg {
			width: 24px;
			height: 24px;
		}

		b {
			font-size: inherit;
		}
	}

	&__hamburger {
		--color-tone: #fff;

		display: none;
	}

	@include from($mobile) {
		&__msgrs-box {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
	
			> * {
				margin-bottom: 9px;
	
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include table {
		&__bottom {
			padding: 13px 0 14px;
		}

		&__burger {
			position: absolute;
			left: 0;
			width: 400px;
			font-size: 14px;
			color: #fff;
			background-color: var(--color-primary-dark);
			visibility: hidden;
			opacity: 0;
			transition: opacity $duration, visibility 0s $duration;

			&.open {
				visibility: visible;
				opacity: 1;
				transition: opacity $duration;
			}

			&-inner {
				display: flex;
				flex-direction: column;
				padding: 35px 25px 30px;
				max-height: 100%;
				overflow: auto;
			}

			&-content {
				display: flex;
				flex-direction: column;
				margin-top: 37px;

				& > * {
					margin-bottom: 14px;
				}

				.firm-info {
					color: #fff;

					&__ic {
						fill: #fff;
					}
				}
			}
		}

		&__logo {
			max-width: 170px;
		}

		&__hamburger {
			display: block;
		}
	}

	@include mobile {
		font-size: 12px;

		&__top {
			padding: 14px 0 15px;
		}

		&__bottom {
			padding: 8px 0;
		}

		&__burger {
			height: calc(100vh - 155px);

			&-inner {
				max-height: 100% !important;
			}
		}

		&__logo {
			max-width: 87px;
		}

		&__msgrs-box {
			flex-grow: 1;
			display: flex;
			align-items: center;

			> * {
				margin-right: 28px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&__btn-callback {
			--btn-height: 24px;

			margin: 0 auto;
		}

		.msgrs {
			font-size: 12px;

			&__link {
				b {
					font-size: 16px;
				}
			}
		}
	}

	@include tel {
		&__burger {
			width: 100%;
		}
	}
}