/*==================== MOLECULES ====================*/
/* accordion */
.accordion {
	> * + * {
		margin-top: 20px;
	}

	&-item {
		$this: &;

		align-self: flex-start;
		max-width: 1100px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 28px rgba(97, 109, 166, 0.15);
		border-radius: 10px;

		&__toggle {
			cursor: pointer;
	
			&-content {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 16px 29px 16px 42px;
				font-weight: 700;	
				color: #414554;
				transition: background-color 0.3s;
	
				> * {
					min-width: 0;
					margin-right: 24px;
	
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		&__indicator {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			background-color: var(--color-primary);
			border-radius: 50%;
			
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				width: 13px;
				height: 2px;
				background-color: #fff;
				transform: translate(-50%, -50%);
				transition: all 0.3s;
			}

			.accordion-item:not(.active) &::after {
				transform: translate(-50%, -50%) rotate(-90deg);
			}
		}
	
		&__body {
			display: none;
	
			&-content {
				padding: 10px 29px 16px 42px;
				opacity: 0;
				transform: translateY(20px);
				transition: opacity 1s,transform .7s;

				.accordion-item.active & {
					opacity: 1;
					transform: translateY(0px);
				}
			}
		}
	}
}

/* # application form*/
.application-f {
	$this: &;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: center;

	&__inputs {
		.b-input {
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__btn {
		align-self: center;
		margin: 40px 0 0;
		min-width: 216px;
	}

	@include mobile {
		&__btn {
			margin-top: 24px;
		}
	}
}

.breadcrumbs {
	&>ul {
		padding: 30px 0;
	}

	@include table {
		>ul {
			padding: 16px 0;
		}
	}
}

/* basket */
.basket {
	&__body {
		li {
			list-style-type: none;
		}
	}

	&__row {
		position: relative;
		display: flex;
		padding: 24px 40px 24px 47px;
		font-size: 14px;
		background-color: var(--bg-card);
		box-shadow: 0px 0px 30px rgba(97, 109, 166, 0.12);
		border-radius: 10px;

		&+& {
			margin-top: 35px;
		}
	}

	&__col {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 5px;

		&:first-child {
			justify-content: flex-start;
			width: pr(540, 1170);
		}

		&--desc {
			display: flex;
			align-items: flex-start;
		}

		&--price {
			justify-content: space-around;
			align-items: center;

			>* {
				margin-right: 32px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&--close {
			flex-grow: 0.2;
			padding-left: 20px;
		}
	}

	&__img {
		display: block;
		margin-right: 36px;
		max-width: 123px;

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__name {
		margin-bottom: em(10, 14);
		font: 700 #{em(16, 14)} var(--fm-1);
		color: #414141;
	}

	&__instalment {
		.instalment {
			&__select {
				width: 95px;
			}
		}
	}

	&__price {
		font: 400 #{em(16, 14)} var(--fm-2);
		color: var(--color-secondary);
		text-align: center;
		white-space: nowrap;

		&-val {
			font-weight: 500;
			font-size: em(32, 16);
			color: #414554;
		}
	}

	@include table {
		&__row {
			padding: 20px 30px;
		}
	}

	@include mobile {
		&__row {
			flex-direction: column;
			padding: 20px;

			&>* {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&__col {
			justify-content: flex-start;
			padding: 0;

			&:first-child {
				width: auto;
			}

			&--desc {
				align-items: flex-start;
			}

			&--price {
				margin-bottom: 0;
			}

			&--close {
				position: absolute;
				right: 18px;
				top: 14px;
				padding: 0;

				.close {
					width: 12px;
					height: 12px;

					&::before,
					&::after {
						height: 1px;
					}
				}
			}
		}

		&__name {
			width: calc(100% - 20px);
		}
	}

	@include tel {
		font-size: 12px;

		&__col {
			&--desc {
				flex-direction: column;
			}

			&--price {
				flex-wrap: wrap;
				row-gap: 15px;
			}
		}

		&__img {
			margin: 0 0 20px;
			max-width: 50px;
		}
	}
}

.buyer-data-list {
	--air-x: 30px;
	--air-y: 42px;

	@include row-flex;

	&>* {
		@include col;
		@include size(6);
	}

	@include table {
		justify-content: center;

		&>* {
			@include size(12);
			max-width: 500px;
		}
	}

	@include mobile {
		--air-y: 20px;
	}
}

.buyer-data {
	position: relative;
	padding: 26px 37px 26px 25px;
	font-size: 14px;
	background-color: var(--color-bg-main);
	border: 1px solid #B8BDD7;
	border-radius: 10px;

	legend {
		display: none;
	}

	&__title {
		margin-bottom: em(37, 20);
		font-weight: 600;
		font-size: 20px;
		color: var(--color-primary);

		.row-cols {
			flex-wrap: nowrap;
		}

		span {
			flex: initial;
		}

		svg {
			width: em(40, 14);
			height: em(40, 14);
		}
	}

	.bayer-data__checkboxses {
		display: flex;
		flex-direction: column;
		padding-left: 8px;

		&>* {
			margin-bottom: em(24, 16);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.b-input {
			width: 100%;

			&--triple {
				.b-input__input {
					display: flex;

					input:nth-of-type(1) {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}

					input:nth-of-type(2),
					input:nth-of-type(3) {
						flex-shrink: 0;
						padding: 0 10px;
						width: pr(93, 470);
						text-align: center;
						transition: all 0s;
					}

					input:nth-of-type(2) {
						background-color: #ECEFF8;
						border-radius: 0;
					}

					input:nth-of-type(3) {
						background-color: #D9DEEE;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
			}
		}

		&--requisites {

			.b-input:nth-child(1),
			.b-input:nth-child(2) {
				width: calc(50% - 8.5px);
			}
		}

		&--address {

			.b-input:nth-child(2),
			.b-input:nth-child(3) {
				width: calc(50% - 8.5px);
			}
		}
	}

	@include mobile {
		padding: 16px 25px 30px;
		font-size: 12px;

		&__title {
			margin-bottom: 20px;
			font-size: 14px;
		}
	}

	@include tel {

		.bayer-data__checkboxses {
			&>* {
				margin-bottom: em(24, 12);
			}
		}

		&__inputs {

			&--requisites,
			&--address {

				.b-input:nth-child(1),
				.b-input:nth-child(2),
				.b-input:nth-child(3) {
					width: 100%;
				}
			}
		}
	}
}

/* basket total */
.basket-total {
	$this: &;

	font-size: 20px;

	&__row {
		--air-x: 10px;
		--air-y: 18px;

		@include row-flex;
		align-items: center;
		justify-content: flex-end;

		>* {
			@include col;
			white-space: nowrap;
		}
	}

	&__price-box {
		display: flex;
		align-items: center;
		padding: 10px 37px;
		min-height: 75px;
		font-weight: 600;
		color: #fff;
		text-align: center;
		background-color: var(--color-primary);
		border-radius: 10px;

		&--transparent {
			margin-left: auto;
			padding: 0 !important;
			min-height: auto;
			color: #414554;
			background-color: transparent;

			#{$this}__title {
				font-weight: 700;
				color: var(--color-primary);
			}

			#{$this}__price {
				margin-left: em(36, 24);
			}

			#{$this}__count {
				margin-left: em(26, 16);
			}
		}
	}

	&__price {
		margin-left: em(26, 24);
		font-family: var(--fm-2);
		font-weight: 700;
		font-size: em(24, 20);
	}

	&__count {
		margin-left: em(19, 16);
		font-weight: 500;
		font-size: em(16, 20);
	}

	.btn {
		width: em(272, 20);
		min-height: 75px;
		font-size: 20px;
		background-color: var(--color-primary-dark);
		background-repeat: 10px;

		&:hover {
			background-color: var(--color-primary);
		}
	}

	@include table {
		&__row {
			justify-content: center;
		}
	}

	@include table {
		font-size: 18px;
	}

	@include mobile {
		font-size: 14px;

		&__price-box,
		.btn {
			min-height: 50px;
		}

		&__price-box {
			padding: 10px 20px;
		}

		&__count {
			font-size: inherit;
		}

		.btn {
			font-size: 14px;
		}
	}
}

.btns-fixed {
	position: fixed;
    left: 20px;
    bottom: 30px;
	z-index: 5;

	@include table {
		bottom: 70px;
	}
}

/* card */
.card {
	$this: &;

	&__before {
		flex-shrink: 0; 

		img, svg {
			display: block;
			max-width: 100%;
		}
	}

	&--style {
		&_default {
			display: flex;
			padding: 20px 28px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: 0px 0px 28px rgba(97, 109, 166, 0.15);
		}
	}
}

/* description item */
.category-links {
	--air-x: 24px;
	--air-y: 25px;

	@include row-flex;

	&>* {
		@include col;
		@include size(12/5.03);
	}

	&__item {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		padding: em(17, 14) em(18, 14);
		font-weight: 700;
		text-align: center;
		justify-content: center;
		font-size: 14px;
		color: #414554;
		border: 1.5px solid #5B669A;
		box-shadow: 0px 0px 28px rgba(97, 109, 166, 0.15);
		border-radius: 10px;
		transition: all 0.2s;

		&:hover {
			color: #fff;
		}

		&-desc {
			margin-top: 0.5em;
			font-weight: 400;
			font-style: italic;
			font-size: em(12, 14);

			&:empty {
				display: none;
			}
		}

		&.btn--orange {
			color: #fff;
			border-color: var(--color-orange);
		}
	}

	@include desctop-min {
		--air-x: 20px;
		--air-y: 15px;
	}

	@include table {
		&>* {
			@include size(4);
		}
	}

	@include mobile {
		justify-content: flex-start;

		&>* {
			@include size(6);
			font-size: 12px;
		}
	}

	@include tel {
		--air-y: 12px;
		--air-x: 8px;

		&__item {
			font-size: 10px;

			&-desc {
				font-size: 9px;
			}
		}
	}
}

.checkbox-pill {
	$this: &;

	&-group {
		&__list {
			@include row-flex;
			--air-x: 10px;
			--air-y: 10px;

			> * {
				@include col;
			}
		}
	}
	
	cursor: pointer;
	position: relative;
	padding: em(10.5, 14) em(20, 14);
	font-weight: 500;
	font-size: 14px;
	color: #AEAEAE;

	input {
		opacity: 0;
		position: absolute;
	}

	&__mark {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		color: inherit;
		border: 1px solid #E0E0E0;
		border-radius: 30px;
		transition: all $duration;
	}

	&__value {
		position: relative;
		transition: all $duration;
	}

	input:checked {
		& ~ #{$this}__mark {
			background-color: var(--color-orange);
			border-color: var(--color-orange);
		}

		& ~ #{$this}__value {
			color: #fff;
		}
	}
}

.desc-item {
	$this: &;

	line-height: (23/16);
	letter-spacing: 0.06em;

	&+&:not(:first-child) {
		margin-top: 60px;
	}

	&__row {
		--air-y: 35px;

		@include row-flex;

		&>* {
			@include col;
			@include size(6);
		}
	}

	&__text {
		padding-top: 0.3em;
	}

	p,
	ul,
	ol {
		margin-bottom: em(24, 16);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.h3,
	h3 {
		font-size: 20px;
		line-height: (26/20);
	}

	&--reverse {
		#{$this}__img {
			order: -1;
		}
	}

	@include desctop-min {

		.h3,
		h3 {
			font-size: 18px;
		}
	}

	@include table {
		$this: &;

		&__row {
			justify-content: center;
		}

		&__text,
		&__img {
			@include size(12);
		}

		&__img {
			max-width: 500px;
		}

		&--reverse {
			#{$this}__img {
				order: initial;
			}
		}
	}

	@include mobile {
		&+&:not(:first-child) {
			margin-top: 40px;
		}

		&__row {
			--air-y: 18px;
		}

		&__text {
			font-size: 14px;

			.h3,
			h3 {
				font-size: 16px;
			}
		}
	}
}

.filters {
	$this: &;

	position: relative;
	padding: 26px 20px 32px;
	color: #fff;
	font-size: 12px;
	background-color: var(--color-primary);
	border-radius: 10px;

	&__head {
		display: none;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		font-size: 14px;
		background-color: var(--color-primary);
	}

	&__title {
		display: inline-flex;
		align-items: center;
		font-weight: 700;

		svg {
			margin-right: 0.5em;
			width: em(24, 14);
			width: em(17, 14);
			fill: #fff;
		}
	}

	&__back {
		margin-left: auto;
		padding: 6px 13px;
		font-weight: 600;
		font-style: italic;
		color: var(--color-primary);
		background-color: #fff;
		border: none;
		outline: none;
		border-radius: 20px;
	}

	&__toggle {
		font: 600 16px var(--fm-1);
	}

	&__body {
		&>* {
			margin-bottom: 35px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__field {
		legend {
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			border: 0;
			padding: 0;
			white-space: nowrap;
			clip-path: inset(100%);
			clip: rect(0 0 0 0);
			overflow: hidden;
		}

		&-toggle {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-style: italic;
			font-weight: 600;
			font-size: em(14, 12);

			svg {
				fill: currentColor;
				transform: rotate(-180deg);
				transition: all 0.3s;

				@at-root #{$this}__field--unopened svg {
					transform: rotate(-90deg);
				}
			}
		}

		&-inner {
			padding-top: em(21, 12);
		}
	}

	&__checkboxes {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&>*+* {
			margin-top: em(11, 12);
		}

		.b-checkbox {
			color: inherit;

			.b-checkbox__custom {
				color: var(--color-primary);
				border-color: #fff;
				background-color: #fff;
			}
		}
	}

	&__controls {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 48px 0 0;
		width: 100%;
		text-align: center;
	}

	&__counter {
		margin-bottom: em(15, 12);
	}

	&__submit {
		width: 100%;
		background-color: var(--color-primary-light);

		&:hover {
			background-color: darken($color-primary-light, 10%);
		}
	}

	@include table {
		display: flex;
		flex-direction: column;
		padding: 0px 28px 0px;
		height: 100%;
		border-radius: 0;
		overflow: auto;

		&-wrap {
			position: fixed;
			z-index: 9999;
			top: 0;
			left: -100%;
			width: 100%;
			height: 100vh;
			opacity: 0;
			pointer-events: none;
			transition: all $duration;

			&.open {
				pointer-events: all;
				opacity: 1;
				transform: translateX(100%);
			}
		}

		&__head {
			position: sticky;
			top: 0;
			z-index: 20;
			display: flex;
			padding: 20px 0 22px;
		}

		&__body {
			margin-bottom: 25px;
		}

		// // &__body {
		// 	max-height: calc(100vh - 61px);
		// 	overflow: auto;
		// }

		&__controls {
			position: sticky;
			bottom: 0;
			z-index: 20;
			justify-content: space-between;
			margin: auto 0 0;
			padding: 10px 0 35px;
			width: 100%;
			background-color: var(--color-primary);
		}
	}
}

.table-wrap {
	overflow-x: auto;
}

.table {
	min-width: 330px;
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;

	th, td {
		padding: 8px;
	}

	thead {
		td,th {
			font-weight: 600;
			color: #fff;
			background-clip: border-box;
			background-color: var(--color-primary);
		}
	}

	tbody {
		td,th {
			height: 45px;
			border: 1px solid #E0E0E0;
		}
	}

	@include mobile {
		font-size: 13px;
	}
}

/* title block */
.title-block {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	row-gap: 25px;
	margin-bottom: 50px;

	&__title {
		margin-right: auto;
		margin: 0 auto 0 0;
		padding: 0 25px 0 0;
		margin-bottom: 0;
	}

	&__subtitle {
		font: 400 16px var(--fm-1);
		color: #414554;
	}

	@include table {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 0;

		&__title {
			margin: 0;
			padding-right: 0;
		}

		&__subtitle {
			margin-top: em(15, 14);
			font-size: 16px;
		}
	}

	@include tel {
		&__subtitle {
			font-size: 14px;
		}
	}
}

.tag-pills {
	--air-x: 28px;
	--air-y: 20px;

	@include row-flex;
	font-weight: 500;
	font-size: 14px;
	color: #656578;
	transition: all 0.2s;

	&:first-child {
		padding-top: 0;
	}

	a {
		@include reset-link;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			color: var(--color-succes);
		}
	}

	&>* {
		@include col;
		display: inline-block;
	}

	&>span {
		font-weight: 600;
		text-decoration: underline;
	}

	button {
		font-weight: 700;
		color: inherit;
		background-color: transparent;
		border: none;
		outline: none;

		&:not(.js-incomplete-toggle--more) {
			.arr {
				top: 0.15em;
				transform: scaleY(-1);
			}
		}

		.arr {
			position: relative;
			top: -0.15em;
			margin-left: 0.3em;
			border-width: 2px;
		}
	}

	@include desctop-min {
		--air-x: 35px;
		--air-y: 12px;
	}

	@include mobile {
		--air-x: 20px;
		--air-y: 10px;

		font-size: 12px;
	}
}

.product-card-search {
	display: flex;
	align-items: center;
	color: #333;

	@at-root {
		.ui-menu.ui-widget.ui-widget-content.ui-autocomplete {
			left: 5vw !important;
			width: 90vw !important;
		}
		.ui-menu.ui-widget-content {
			z-index: 10000;
			border: none;
			background-color: #fff;
			border-radius: 10px;

			&.ui-state-active {
				border-color: transparent !important;
				background-color: transparent;

				.product-card-search {
					color: #333;
				}
			}
		}

		.mse2-ac-link.ui-menu-item-wrapper {
			padding: 1em 2em 2em 2em !important;
			transition: background-color $duration;

			&:hover {
				background-color: rgba(145, 157, 214, 0.16);
			}

			&.ui-state-active {
				margin: 0;
				background-color: rgba(145, 157, 214, 0.16) !important;
				border: none !important;
			}
		}
	}

	&__inner {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		& > * {
			margin-top: 0.4em;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__img {
		flex-shrink: 0;
		display: block;
		margin: 0 25px 0 0;
		width: 108px;
		border-radius: 5px;
		overflow: hidden;
	}
	&__availability {
		font-weight: 500;
	}

	&__title {
		display: inline-flex;
		align-items: center;
		margin-right: auto;
		font-weight: 700;
		color: #414141 !important;

		.arrow {
			flex-shrink: 0;
			display: inline-block;
			margin-right: 16px;
		}
	}

	&__prop {
		color: #757575;

		p {
			display: inline-block;
			margin-bottom: 0;
		}
	}

	&__price {
		margin-top: 0.6em;
		font-weight: 600;
	}

	a {
		color: #333;
	}

	@include mobile {
		align-items: flex-start;
		font-size: 14px;

		@at-root {
			.ui-menu.ui-widget-content {
				border-radius: 5px;
			}

			.mse2-ac-link.ui-menu-item-wrapper {
				padding: 0.5em 1em 1em 1em !important;
			}
		}

		&__img {
			width: 60px;
		}

		&__title {
			.arrow {
				width: 35px;
				height: 35px;
			}
		}
	}
}

.product-card {
	$this: &;

	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px 15px 30px;
	font-size: 14px;
	background-color: var(--bg-card);
	border-radius: 10px;
	overflow: hidden;
	transition: all 0.2s;

	&:hover {
		box-shadow: 0px 0px 49px rgba(97, 109, 166, 0.33);

		#{$this}__price {
			color: #fff;
			background-color: var(--color-primary);

			&-val {
				color: #fff;
			}
		}
	}

	&__labels {
		position: absolute;
		left: 20px;
		top: 9px;
		display: flex;
		align-items: center;

		&>* {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;
		margin-bottom: 1em;
		padding-bottom: 15px;
		color: #414141;
		border-bottom: 1px solid rgba(97, 109, 166, 0.19);
	}

	&__img {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin: 0 auto;
		height: 170px;

		> * + * {
			margin-left: 15px;
		}

		img {
			max-width: 100%;
			max-height: 100%;

			&:not(:last-child:first-child) {
				max-width: calc(50% - 7.5px);
			}
		}
	}

	&__title {
		margin-top: em(15, 16);
		font-weight: 700;
		font-size: em(16, 14);
		text-align: center;
	}

	&__instalment {
		justify-content: space-between;
		margin-bottom: auto;
		padding-bottom: 1em;

		.instalment {
			&__title {
				margin-right: 0;
				width: 100%;
				text-align: center;
			}

			&__price {
				flex-grow: 1;
				margin-right: 0;
				padding-right: 10px;
				width: calc(100% - 93px);
				line-height: 1.1;

				span {
					white-space: nowrap;
				}
			}

			&__select {
				flex-shrink: 0;
				width: 83px;
				height: 30px;
				background-position: right 10px center;

				select {
					padding: 5px 20px 5px 13px;
				}
			}
		}

		& ~ & {
			margin-bottom: 0;
		}
	}

	&__price,
	&__bottom {
		margin-left: -15px;
		margin-right: -15px;
	}

	&__price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: auto;
		padding: 11px 16px;
		color: #757575;
		border-top: 1px solid rgba(97, 109, 166, 0.19);
		transition: all 0.2s;

		&-val {
			margin-top: -0.15em;
			font-weight: 700;
			font-size: em(18, 14);
			color: var(--color-primary);
			transition: all 0.2s;
		}

		del {
			font-size: em(12, 14);
			color: #B8BDD7;
		}
	}

	&__bottom {
		margin-bottom: -30px;

		.btn {
			width: 100%;
			border-radius: 0;
		}
	}

	&__to-basket-btn {
		background-color: var(--color-primary-dark);
	}

	@include mobile {
		padding: 15px 11px 20px;
		font-size: 10px;

		&__instalment {
			justify-content: space-between;
			padding-bottom: 1em;

			.instalment {
				&__price {
					width: calc(100% - 80px);
					font-size: 12px;
				}

				&__select {
					font-size: 10px;
					width: 70px;
					height: 22px;

					select {
						font-size: inherit;
					}
				}
			}
		}

		&__price,
		&__bottom {
			margin-left: -11px;
			margin-right: -11px;
		}

		&__bottom {
			margin-bottom: -20px;
		}

		&__price {
			flex-wrap: wrap;

			&-title {
				margin-bottom: 5px;
				width: 100%;
				text-align: center;
			}

			&-val {
				font-size: 16px;
			}

			del {
				font-size: 10px;
			}
		}
	}

	@include tel {
		&__img {
			height: 135px;
		}
	}

	@include to(390) {
		&__img {
			height: 120px;
		}
	}
}

/* products */
.products {
	display: flex;
	flex-direction: column;

	&__controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 35px;
	}

	&__list {
		--air-x: 21px;
		--air-y: 20px;

		@include row-flex;
		justify-content: flex-start;

		& > * {
			@include col;
			@include size(12/5.01);
		}

		@include from($table) {
			@at-root .boxes & {
				& > * {
					@include size(3);
				}

				@include desctop-min {
					& > * {
						@include size(4);
					}
				}
			}
		}
	}

	.record-control {
		position: relative;
		align-self: center;

		@include from($table) {
			&__counter {
				position: absolute;
				left: calc(100% + 45px);
				top: 50%;
				white-space: nowrap;
				transform: translateY(-50%);
			}
		}
	}

	@include desctop-min {
		&__list {
			&>* {
				@include size(3);
			}
		}
	}

	@include table {
		&__list {
			&>* {
				@include size(4);
			}
		}
	}
	

	@include mobile {
		&__list {
			--air-x: 15px;
			--air-y: 15px;

			&>* {
				@include size(6);
			}
		}
	}

	@include tel {
		&__controls {
			font-size: 10px;
		}
	}
}

.products-slider {
	padding: 40px 0;
	overflow: visible;
	user-select: none;

	&-wrap {
		margin-top: -40px;
		overflow: hidden;
	}

	&::after,
	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		top: 0;
		z-index: 4;
		height: 100%;
		width: 5000px;
		background: linear-gradient(88.61deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4) 7vw, rgba(255, 255, 255, 0.9) 15vw);
	}

	&::before {
		right: 100%;
		transform: scale(-1);
	}

	&::after {
		left: 100%;
	}

	&__slide {
		height: auto;

		a {
			display: block;
		}
	}

	@include table {
		&__slide {
			width: 281px !important;
		}

		&::before,
		&::after {
			display: none;
		}
	}

	@include mobile {
		&::after {
			display: block;
			left: 90%;
		}

		&__slide {
			width: 200px !important;
		}
	}

	@include tel {
		&__slide {
			width: 170px !important;
		}
	}
}

/* product */
.product {
	.product__title {
		margin-bottom: em(30, 32);
		font: 600 32px var(--fm-1);
		color: #19191C;
		text-align: left;
	}

	&__showcase {
		--air-x: 50px;

		display: flex;
		padding: 69px 65px 55px 32px;
		background-color: var(--bg-card);
		box-shadow: 0px 0px 49px rgba(97, 109, 166, 0.1);
		border-radius: 10px;

		&>* {
			min-width: 0;
		}
	}

	&__gallery {
		position: relative;
		flex-shrink: 0;
		margin-right: 50px;
		width: 350px;

		.gallery__full {
			margin: 0 auto;
			max-width: 430px;
		}
	}

	&__details {
		flex-grow: 1;

		&>* {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__labels {
		position: absolute;
		top: 0px;
		left: 50%;
		z-index: 2;
		display: flex;
		transform: translate(-50%, -50%);

		&>*+* {
			margin-left: 17px;
		}

		.label-prod {
			width: 51px;
			height: 51px;
		}
	}

	&__instalment {
		border-left: none;
		border-right: none;

		.instalment {
			&__title {
				font-weight: 500;
			}

			&__btn-buy {
				padding-left: em(40, 14);
				padding-right: em(40, 14);
			}

			&__price {
				font-size: em(20, 16);
			}

			&__select {
				width: 107px;
			}
		}
	}

	&__price-box {
		display: flex;
		align-items: center;

		>*+* {
			margin-left: 32px;
		}
	}

	&__price {
		display: flex;
		align-items: flex-end;
		font-weight: 600;

		&-val {
			font-family: var(--fm-2);
			font-size: 32px;
		}

		del {
			margin-left: 1em;
			font-size: 16px;
			font-family: var(--fm-2);
			color: #EA5151;
		}
	}

	&__palette {
		&-item {
			display: inline-flex;
			align-items: center;
			color: #414141;
			
			& > * + * {
				margin-left: em(13, 16);
			}

			i {
				display: inline-block;
				width: em(48, 16);
				height: em(48, 16);
				border-radius: em(10, 16);
			}
		}
	}

	&__btn-to-basket {
		min-width: 208px;
	}

	.table-wrap {
		width: 100%;
	}

	&__table {
		width: 100%;
		font-size: 14px;
		text-align: center;

		td,
		th {
			padding: 10px;
			height: 45px;
			border: 1px solid #E0E0E0;

			&:first-child {
				min-width: 115px;
			}
		}
	}

	@include desctop-min {
		.product__title {
			font-size: 26px;
		}
	}

	@include table {
		&__showcase {
			flex-direction: column;
			padding: 28px 20px 30px;

			&>* {
				width: 100%;
			}

			.gallery {
				&__slider-wrap {
					margin: 0 auto;
				}
			}
		}

		&__gallery {
			margin: 0 auto 50px;
			padding-right: 0;
		}

		&__details {
			display: flex;
			flex-direction: column;
		}

		&__title {
			align-self: flex-start;
		}
	}

	@include mobile {
		.product__title {
			margin-bottom: 1em;
			font-size: 16px;
		}

		&__labels {
			.label-prod {
				width: 39px;
				height: 39px;
				font-size: 11px;
			}
		}

		&__details {
			font-size: 12px;

			>* {
				margin-bottom: 20px;
			}
		}

		&__instalment {
			.instalment {
				&__price {
					width: calc(100% - 120px);
				}
			}
		}

		&__price {
			flex-wrap: wrap;
			row-gap: 8px;

			&-val {
				font-size: 18px;
				white-space: nowrap;
			}

			del {
				margin-left: 12px;
				font-size: 12px;
				white-space: nowrap;
			}
		}

		&__palette {
			&-item {
				i {
					width: 26px;
					height: 26px;
					border-radius: 3px;
				}
			}
		}

		&__btn-to-basket {
			margin-left: 14px;
			min-width: 140px;
		}

		&__table {
			font-size: 12px;

			td,
			th {
				padding: 7px;
				height: 32px;
			}
		}
	}
}

.order-item {
	text-align: left;

	&__row {
		display: flex;
		justify-content: space-between;

		> * {
			margin-right: 24px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__prod {
		display: flex;
		align-items: center;

		&-img {
			flex-shrink: 0;
			margin-right: 23px;
			max-width: 50px;

			> * {
				max-width: 100%;
			}
		}

		&-title {
			font-weight: 500;
			line-height: calc(20/16);
		}
	}

	&__price {
		white-space: nowrap;
		font-weight: 700;
		color: var(--color-primary);

		&-val {		
			font-size: em(32, 16);
		}

		del {
			position: relative;
			display: inline-block;
			margin-left: 1em;
			font-size: 1em;
			text-decoration: none;
			color: var(--color-red);

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: -5%;
				width:  calc(100% + 10%);
				border-bottom: 1px solid currentColor;
				transform: translateY(-50%);
			}

			small {
				font-size: em(12, 16);
			}
		}
	}

	@include mobile {
		&__row {
			flex-direction: column;

			> * {
				margin: 0 0 10px;

				&:last-child {
					margin: 0;
				}
			}
		}

		&__price {
			text-align: center;
		}
	}
}

/*menu*/
.menu {
	$this: &;
	$fz-lvl-2: 16px;

	@mixin style-active-link-lvl-2 {
		color: #fff;
		background-color: var(--color-primary);
	}

	position: relative;
	z-index: 1;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-weight: 600;

	/***********************************/
	.j-toggle-hang {
		cursor: pointer;
	}

	&__submenu {
		padding: 0;
		font-weight: 500;
	}

	&__item {
		margin-bottom: 0;
		list-style-type: none;
	}

	&__link {
		@include reset-link;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		transition: color 0.2s, background-color 0.2s;

		&:hover {
			text-decoration: none;
		}
	}

	&>&__item>&__link {
		text-transform: uppercase;
	}

	/* hamburger на зависание подменю*/
	&__toggle {
		$this: &;

		margin-top: -0.2em;
		margin-right: 10px;
		width: 30px;
		height: 16px;

		span,
		span::before,
		span:after {
			height: 1.6px;
		}

		span::before {
			top: -6px;
		}

		span::after {
			bottom: -6px;
		}
	}

	&__arr {
		display: inline-block;
		margin: 0 0 0 em(8, 16);
		vertical-align: middle;
		fill: currentColor;
		transition: transform $duration;
	}

	.menu__item--hang_open,
	.menu__item--hang:hover {
		&>ul {
			opacity: 1;
			pointer-events: all;
			visibility: visible;
			transform: translateY(0);
			transition: opacity 0.3s, transform 0.3s;
		}

		.menu__toggle {

			span,
			span::before,
			span::after {
				background-color: var(--color-orange);
			}
		}

		>a {
			color: var(--color-orange);
		}
	}

	@include from($table) {
		$this: &;

		/* 1, 2, n...-ый уровень UL, скрытый, вертикальный */
		&__submenu {
			position: absolute;
			min-width: 330px;
			max-width: 350px;
			color: var(--color-text);
			background-color: #fff;
			opacity: 0;
			visibility: hidden;
			transform: translateY(20px);
			box-shadow: 0px 0px 47px rgba(97, 109, 166, 0.47);
			transition: transform $duration, opacity $duration, visibility 0s $duration;

			.menu__arr {
				svg {
					transform: rotate(-90deg);
				}
			}
		}

		/* 2-ый уровень UL, скрытый, вертикальный */
		&__submenu.lvl-2 {
			top: 100%;
			left: 0;

			@if $fz-lvl-2 {
				font-size: $fz-lvl-2;
			}
		}

		/* 3-ый уровень UL, скрытый, вертикальный */
		.lvl-2 &__submenu {
			left: 100%;
			top: 0;
			min-width: 290px;
			max-width: 320px;
			color: #fff;
			background-color: var(--color-primary-dark);
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s, visibility 0s 0.5s, transform 0.5s;
		}

		/* li level 1,2,3... */
		&__item {
			position: relative;

			&:hover {
				z-index: 2;

				>#{$this}__submenu {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition: opacity 0.2s, transform $duration;
				}
			}
		}

		/*1-ый уровень, не скрытый, горизонтальный*/
		&>&__item {
			margin-right: 25px;

			&:last-child {
				margin-right: -7px;
			}

			&.active>#{$this}__link {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: #fff;
				}
			}

			&:hover>a#{$this}__link {
				color: var(--color-orange);
			}

			&:hover>.menu__link .menu__arr {
				transform: rotate(180deg);
			}

			&>#{$this}__link {
				position: relative;
				padding: 23px 10px;
				transition: all $duration;
			}
		}

		/* li co 2-го уровня меню */
		&__submenu &__item {

			/* active, hover item lvl-2 */
			&:hover>a.menu__link {
				@include style-active-link-lvl-2;

				.menu__arr {
					fill: #fff;
				}
			}

			&.active>.menu__link {
				font-weight: 700;
				text-decoration: underline;
			}
		}

		/* li co 3-го уровня меню */
		&__submenu.lvl-3 &__item {

			/* active, hover item lvl-2 */
			&:hover>a.menu__link {
				background-color: var(--color-orange);
			}
		}

		/*ссылки со 2-го уровня меню*/
		&__submenu &__link {
			padding: em(16, 16) 27px em(14, 16) em(35, 16);
			width: 100%;
		}

		&__arr.js-toggle-submenu {
			pointer-events: none;
		}

		/*Вложенное меню в 1-ом уровне*/
		&__item--hang {
			display: flex;
			align-items: center;
			padding-left: 0;

			&.active>ul {
				opacity: 1;
				pointer-events: all;
				visibility: visible !important;
				transition: opacity $duration !important;
			}

			#{$this}__hamburger.active+.menu__link {
				color: var(--color-primary);
			}
		}
	}

	@include table {
		flex-direction: column;
		align-items: flex-start;

		/* hamburger на зависание подменю*/
		&__toggle {
			margin: -0.35em 1em 0 0px;
		}

		&__link:not([href]),
		&__item.active>.menu__link {
			text-decoration: underline;
			opacity: 0.8;
		}

		&__item--hang_open>&__submenu {
			display: block;
		}

		&__link {
			padding: em(10, 12) 10px em(10, 12) 0;
			padding-left: 1.8em;

			&:hover {
				color: var(--color-orange);
			}
		}

		&__arr {
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			margin-left: 15px;
			height: 100%;
			width: 40px;

			&.js-toggle-submenu--active {
				transform: rotate(180deg);
			}

			svg,
			img {
				margin: auto;
			}
		}

		&>&__item {
			width: 100%;
		}

		&>&__item>&__link {
			justify-content: flex-start;
			padding: em(12, 12) 10px em(12, 12) 0;
			padding-left: 3.2em;
			width: 100%;
		}

		&>&__item--hang>.menu__link {
			padding-left: 0;
		}

		&__link {
			position: relative;
			width: 100%;
		}

		&__submenu {
			display: none;
			padding-left: 1em;
		}
	}
}

.icon-text {
	display: inline-flex;
	align-items: center;
	
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: em(48, 16);
		height: em(48, 16);
		background: #FFFFFF;
		box-shadow: 0px 4px 24px rgba(207, 207, 232, 0.60), 0px 1px 1px rgb(0, 0, 0, 0.1);
		border-radius: em(10, 16);

		svg, img {
			width: pr(30, 48);
			height: pr(30, 48);
		}
	}

	&__text {
		margin-left: em(13, 16);
	}

	@include mobile {
		&__icon {
			width: em(26, 12);
			height: em(26, 12);
			border-radius: 3px;
		}
	}
}