/*~~~~~~~~~~~~~~~~~~~~~~~~~ COMMON BUTTON ~~~~~~~~~~~~~~~~~~~~~~~~~*/
%btn {
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.btn {
	--color-bg: var(--color-primary);
	--color-text: #fff;
	--btn-height: 45px;

	$this: &;
	@extend %btn;
	
	padding: em(6, 14) em(24, 13);
	min-width: 100px;
	min-height: var(--btn-height);
	font: 600 14px var(--fm-1);
	line-height: 1.22;
	text-align: center;
	color: var(--color-text);
	background-color: var(--color-primary);
	background-clip: border-box;
	border: 1px solid transparent;
	outline: none;
	border-radius: 5px;
	transition: all 0.2s;

	&:disabled {
		pointer-events: none;
		cursor: no-drop;
		opacity: 0.5;
	}

	&:hover {
		--color-bg: var(--color-primary-dark);
	}
	
	@at-root a#{&} {
		&:hover {
			text-decoration: none;
		}
	}

	&__icon {
		flex-shrink: 0;
		display: block;
		width: em(15, 13);
		height: em(15, 13);
		vertical-align: middle;
		transform: translateY(-0.1em);
		transition: all 0.2s;

		&:first-child {
			margin-right: em(9.5, 13);
		}

		&:last-child {
			margin-left: em(13, 13);
		}

		> * {
			margin: 0 !important;
			max-height: initial;
			max-width: initial;
		}

		&#{&}--pos {
			&_left-bottom {
				position: absolute;
				left: 0;
				bottom: 0;
				margin: 0;
			}
		}
	}

	&--secondary {
		--color-bg: var(--color-secondary);
		--color-text: #fff;

		&.active,
		&:hover {
			--color-bg: #2d5437;
			--color-text: #fff;

			color: var(--color-text);
		}
	}

	&--white {
		--color-bg: #fff;
		--color-text: var(--color-bg-main);

		box-shadow: 0px 0px 20px rgba(190, 201, 255, 0.5);

		&.active,
		&:hover {
			--color-bg: var(--color-primary);
			--color-text: #ff0000;
		}

		#{$this}__icon {
			fill: var(--color-text);
		}
	}

	&--orange {
		--rgb: 255, 112, 0;
		--color-bg: var(--color-orange);
		--color-text: #fff;

		box-shadow: 0px 0px 33px rgba(97, 109, 166, 0.22);

		&.active,
		&:hover {
			--color-bg: var(--color-orange-darken);
		}
	}

	&--dark {
		--color-bg: #414554;
	}

	&--sm {
		min-height: em(40, 14);
		text-transform: none;
	}

	&--lg {
		padding: em(15, 16) em(52, 16);
		min-height: 60px;
		font-size: 16px;

		@include mobile {
			min-height: 45px;
			font-weight: 600;
			font-size: 12px;
		}
	}

	&--brs {
		&_half {
			border-radius: 50px;
		}
	}

	&--effect {
		&_blink {
			animation: blink infinite 2s;
	
			&::after {
				pointer-events: none;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				box-shadow: 0 0 0 6px rgba(var(--rgb), 0.16);
			}
		}
	}

	&--size {
		&_m {
			--btn-height: 40px;

			font-size: 13px;
		}
	}

	&--size-height {
		&_xxs {
			--btn-height: 30px;
		}
	}

	&--mob {
		@include mobile {
			&_icon {
				padding: em(5, 13);
				min-width: auto;
				width: var(--btn-height);

				#{$this}__value {
					display: none;
				}

				#{$this}__icon {
					margin: 0;
				}
			}
		}
	}

	@keyframes blink {
		0% {
			box-shadow: 0 0 0 0px rgba(var(--rgb), 70%);
		}

		70% {
			box-shadow: 0 0 0 em(20, 13) rgba(var(--rgb), 0%);
		}

		100% {
			box-shadow: 0 0 0 0 rgba(var(--rgb), 0%);
		}
	}
}



.btn-ic {
	cursor: pointer;
	flex-shrink: 0;
	display: inline-flex;
	width: 47px;
	height: 47px;
	text-align: center;
	background-color: var(--color-primary);
	border-radius: 10px;
	border: none;
	outline: none;
	transition: all 0.2s;

	&:hover {
		background-color: var(--color-primary-dark);
	}

	svg {
		margin: auto;
		width: pr(31, 47);
		height: pr(31, 47);
		fill: #fff;
	}
}

.btn-link {
	display: inline-flex;
	align-items: center;
	padding: 0;
	font-weight: 500;
	color: var(--color-primary);
	background-color: transparent;
	border: none;
	outline: none;
	text-align: left;

	&:hover {
		text-decoration: underline;
	}

	&__icon {
		display: block;
		width: 15px;
		height: 15px;
		color: currentColor;
		fill: currentColor;

		&:first-child {
			margin-right: em(7, 14);
		}
		&:last-child {
			margin-left: em(7, 14);
		}
	}

	&--2 {
		color: var(--color-green)
	}
}

.v-up {
	@extend %btn;

	cursor: pointer;
	position: fixed;
	right: 24px;
	bottom: 105px;
	z-index: 250;
	padding: 0;
	width: 50px;
	height: 50px;
	min-width: auto;
	border-radius: 50%;
	background-color: #fff;
	border: none;
	box-shadow: 0px 0px 20px rgba(190, 201, 255, 0.5);
	opacity: 0;
	pointer-events: none;
	transition: background-color $duration, opacity $duration;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&:hover {
		background-color: var(--color-primary);

		svg {
			fill: #fff;
		}
	}

	svg,
	img {
		margin: auto;
		width: pr(10, 50);
		height: pr(19, 50);
		fill: #616DA6;
		transition: fill $duration;

		path {
			fill: inherit;
		}
	}

	@include mobile {
		bottom: 140px;
	}
}

.basket-btn {
	position: relative;
	display: inline-flex;
	padding-right: 11px;
	width: 51px;
	height: 40px;
	font-size: 18px;
	color: #fff;

	svg {
		fill: #fff;
	}

	strong {
		position: absolute;
		top: 3px;
		right: 0;
		transform: translate(0, -50%);
	}
}

.search-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 40px;
	height: 40px;
	background-color: var(--color-orange);
	border-radius: 50%;
	border: none;
	outline: none;

	>* {
		margin: auto;
		height: pr(15, 40);
		width: pr(15, 40);
	}

	svg {
		fill: #fff;
	}
}

.msg-menu-calculator {
	bottom: 127px;
	left: 35px;
	right: auto;

	.share .msg-icon {
		&::before {
			display: none;
		}
		&:hover {
			background-color: var(--color-primary);

			svg path {
				fill: #fff;
			}
		}

		svg,path {
			transition: all 0.3s;
		}
	}

	.share .msg-icon {
		background: #FFFFFF;
		box-shadow: 0px 0px 20px rgba(190, 201, 255, 0.5);

		svg {
			fill: #fff;
		}
	}
}

.button {
	$this: &;

	position: relative;

	&--bg {
		&_green {
			background: #9DA65D;
			transition: all $duration;

			&:hover,
			&:active {
				background: darken(#9DA65D, 7%);
				transition: all $duration;
			}
		}
	}

	&--bg_green#{$this}--effect_blink {
		box-shadow:  0 0 13px rgba(#9DA65D, 0.13);
		animation: _pulse infinite 2s;

		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: 100%;
			height: 100%;
			border-radius: 50px;
			box-shadow:  0px 6px 16px rgba(#9DA65D, 0.23);
			transform: translate(-50%, -50%);
		}

		@keyframes _pulse {
			0% {
				box-shadow: 0 0 0 0px rgba(#b3d6af, 100%);
			}
	
			70% {
				box-shadow: 0 0 0 27px rgba(#b3d6af, 0%);
			}
	
			100% {
				box-shadow: 0 0 0 0 rgba(#b3d6af, 0%);
			}
		}
	}
}