/*~~~~~~~~~~~~~~~~~~~~~~~~~ FONT FAMILY ~~~~~~~~~~~~~~~~~~~~~~~~~*/
@mixin font-face($font-family, $weight, $url, $file-name, $style: normal) {
	@font-face {
		font-family: "#{$font-family}";
		src: url("#{$url}#{$file-name}.woff2") format("woff2"), url("#{$url}#{$file-name}.woff") format("woff");
		font-weight: #{$weight};
		font-display: swap;
		font-style: $style;
	}
}

@include font-face("Open Sans", 700, "./../../fonts/", "OpenSans-Bold");


