/*~~~~~~~~~~~~~~~~~~~~~~~~~ VARIABLES ~~~~~~~~~~~~~~~~~~~~~~~~~*/
// spacing
$spacing-side: 30px;

// font-size
$fz: 16px;

// colors
$color-primary-light: rgba(255, 255, 255, 0.19);
$color-primary: #9DA65D;
$color-primary-dark: #5F5645;

$color-orange: #5F5645;
$color-orange-darken: #de6101;
$color-green: #9DA65D;
$color-red: #D15B5B;

$color-text: #656578;
$color-link: #337ab7;

$color-bg-main: #fff;

// durations
$duration: 0.3s;

:root {
	/* spacings */
	--spacing-side: #{$spacing-side};

	/*font-family*/
	--fm-1: "Raleway", sans-serif;
	--fm-2: "Open Sans", sans-serif;

	/*colors*/
	--color-primary-light: #{$color-primary-light};
	--color-primary: #{$color-primary};
	--color-primary-dark: #{$color-primary-dark};

	--color-orange: #{$color-orange};
	--color-orange-darken: #{$color-orange-darken};
	--color-green: #{$color-green};
	--color-red: #{$color-red};

	--color-text: #{$color-text};
	--color-link: #{$color-link};

	--color-bg-main: #{$color-bg-main};

	--basic-black: #000;
	--basick-white: #fff;

	@include table {
		--spacing-side: 15px;
	}

	@include mobile {
		--spacing-side: 10px;
	}
}
