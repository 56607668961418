// html,
// body {
// 	font-family: var(--fm-1);
// 	font-size: $fz;
// 	font-weight: 400;
// 	color: var(--color-text);
// }

// body {
// 	height: 1px;
// 	min-height: 100vh;
// 	scrollbar-color: var(--scroll-thumb-color) var(--scroll-bar-color); /* «цвет ползунка» «цвет полосы скроллбара» */
// 	scrollbar-width: thin; /* толщина */
// }

// ::-webkit-scrollbar {
// 	width: 0.6rem;
// 	height: 0.6rem;
// 	background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
// 	background-color: var(--color-primary);
// 	border-radius: 0.5em;
// }

// ::-webkit-scrollbar-thumb:hover {
// 	background-color: lighten($color-primary, 10%);
// }

/*--------------------------------Common--------------------------------*/
.container {
	.container {
		padding-left: 0;
		padding-right: 0;
	}
	
	&--size_m {
		width: auto;
		max-width: 970px;
	}
}

.site {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__content {
		padding: 5px 0 rem(80px);
		flex: 1;

		// h1,
		// .h1 {
		// 	margin-bottom: 65px;
		// }

		// .header--bg_transparent ~ & {
		// 	padding-top: 0;
		// }

		@include desctop-min {
			padding: 5px 0 7rem;
		}

		@include table {
			padding: 5px 0 5rem;

			// .bread-crumb + & {
			// 	padding-top: 15px;
			// }
		}

		@include mobile {
			padding: 5px 0 4rem;
		}
	}
}
