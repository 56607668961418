/* copy notification */
[data-copy] {
	cursor: pointer;
	position: relative;
}

.copy-notification {
	position: absolute;
	z-index: 100;
	bottom: calc(100% + 10px);
	padding: 10px;
	width: max-content;
	max-width: 80vw;
	font-size: 16px;
	text-align: center;
	color: var(--color-primary);
	background-color: rgba(255, 255, 255, 0.95);
	box-shadow: 0px 0px 10px rgba(48, 130, 255, 0.2);
	border-radius: 10px;
	opacity: 0;
	transition: opacity 0.4s;

	&--animated {
		opacity: 1;
	}
}

.gallery-prod {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 600px;
	width: 100%;

	&__thumbs-wrap {
		display: flex;
		align-items: center;
		margin: 30px auto 0;
		height: 80px;
		max-width: 100%;
	}

	&__thumbs {
		margin: 0 25px;
		height: 100%;

		&-item {
			cursor: pointer;
			position: relative;
			display: inline-block;
			padding: 0 15px;
			height: 100%;
			width: auto;
			background-color: var(--bg-card);
			border-radius: 5px;
			border: 0.5px solid transparent;
			transition: all $duration;

			&.swiper-slide-thumb-active {
				border-color: var(--color-primary);
				box-shadow: 0px 0px 49px rgba(97, 109, 166, 0.1);
			}

			img {
				display: block;
				max-height: 100%;
				max-width: 100%;
			}
		}
	}

	&__full-wrap {
		width: 100%;
	}

	&__full {
		&-slide {
			position: relative;
			display: block;
			overflow: hidden;

			&::before {
				content: "";
				display: block;
				padding-bottom: 157%;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
			}

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				display: block;
				width: 100%;
				height: 100%;
				max-height: 100%;
				max-width: 100%;
				object-fit: contain;
				transform: translate(-50%, -50%);
			}
		}
	}

	&__labels {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: grid;
		row-gap: 30px;

		.product-label {
			font-size: 20px;
			width: 83px;
			height: 83px;
		}
	}

	@include table {
		max-width: none;

		&__full-wrap {
			margin: 0 auto;
			max-width: 390px;
		}

		.slider {
			&-nav {
				display: block;
			}
		}

		&__labels {
			.product-label {
				width: 70px;
				height: 70px;
				font-size: 16px;
			}
		}
	}

	@include mobile {
		&__thumbs-wrap {
			margin-top: 18px;

			.slider {
				&-arr {
					width: 27px;
					height: 27px;
				}
			}
		}

		&__thumbs {
			margin: 0 20px 0;
			height: 51px;

			&-item {
				padding: 0 14px;
			}
		}

		&__full {
			&-slide {
				&::before {
					padding-bottom: 100%;
				}
			}
		}

		&__labels {
			row-gap: 20px;

			.product-label {
				width: 60px;
				height: 60px;
				font-size: 14px;
			}
		}
	}
}

/* search panel */
.search-panel {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	padding-top: 180px;
	width: 100%;
	height: 100%;
	font-size: 16px;
	background: rgba(51, 51, 51, 0.26);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s, visibility 0s 0.4s;

	header.fixed~& {
		padding-top: 100px;
	}

	label {
		width: 100%;
	}

	&__input {
		width: 100%;
		background-color: transparent;
	}

	&.open {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.4s;
	}

	&__inner {
		display: flex;
		align-items: center;
		padding: 8px 35px 8px 26px;
		background-color: #fff;
		border-radius: 10px;

		label {
			position: relative;
			flex-grow: 1;
			display: flex;
			align-items: center;

			i {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);

				svg {
					margin-right: 32px;
					width: 25px;
					height: 25px;
					fill: var(--color-orange);
				}
			}
		}
	}

	&__input {
		appearance: none;
		flex-grow: 1;
		padding: 0 115px 0 56px;
		height: 45px;
		width: 100%;
		border: none;
		outline: none;
		background-color: transparent;
		text-overflow: ellipsis;

		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}

		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}

	&__clear,
	&__close {
		position: absolute;
		top: 50%;
		background-color: transparent;
		border: none;
		outline: none;
		transform: translateY(-50%);
	}

	&__clear {
		right: 40px;
		margin-top: -2px;
		height: 100%;
		transition: opacity $duration;
	}

	&__clear:not(.is-clear) {
		opacity: 0;
		pointer-events: none;
	}

	&__close {
		box-sizing: content-box;
		right: 0;
		width: 19px;
		height: 19px;
		border: 5px solid transparent;
		transform: translateY(-50%) rotate(-45deg);

		&::before,
		&::after {
			content: "";
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			height: 1px;
			width: 100%;
			border-radius: 1px;
			background-color: #333;
			transform: translateY(-50%);
		}

		&::after {
			transform: translateY(-50%) rotate(-90deg);
		}
	}

	@include table {
		padding-top: 80px !important;
	}

	@include mobile {
		&__inner {
			padding: 8px 20px 8px;

			label {
				position: relative;
				flex-grow: 1;
				display: flex;
				align-items: center;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);

					svg {
						margin-right: 15px;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		&__input {
			appearance: none;
			flex-grow: 1;
			padding: 0 100px 0 32px;
			height: 35px;
			width: 100%;
			background-color: transparent;
			text-overflow: ellipsis;

			&::-ms-clear {
				display: none;
				width: 0;
				height: 0;
			}

			&::-ms-reveal {
				display: none;
				width: 0;
				height: 0;
			}

			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				display: none;
			}
		}

		&__clear {
			margin-top: -1px;
			font-size: 14px;
		}
	}

	// @include table {
	// 	width: 100%;

	// 	.container {
	// 		padding: 0;
	// 	}

	// 	&__inner {
	// 		label {
	// 			display: flex;
	// 			align-items: center;

	// 			i {
	// 				display: inline-block;
	// 				margin-right: em(8, 10);

	// 				svg {
	// 					width: em(11, 10);
	// 					height: em(11, 10);
	// 					fill: var(--color-primary);
	// 				}
	// 			}
	// 		}
	// 	}

	// 	&__input {
	// 		height: 30px;
	// 	}

	// 	&__clear {
	// 		display: none;
	// 	}
	// }
}

/*general slider settings*/
.slider {
	&-wrap {
		position: relative;
	}

	&-nav {
		position: absolute;
		top: 50%;
		z-index: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		transform: translateY(-50%);

		.swiper-button-lock {
			display: none !important;
		}
	}

	&-arr {
		flex-shrink: 0;
		display: inline-block;
		width: 32px;
		height: 32px;
		text-align: center;
		background-color: #fff;
		border-radius: 50%;
		border: none;
		outline: none;
		box-shadow: 0px 0px 12px rgba(97, 109, 166, 0.2);
		transition: all $duration;

		&:hover {
			background-color: var(--color-primary);

			svg {
				fill: #fff;
			}
		}

		svg,
		img {
			display: inline-block;
			margin: auto;
			vertical-align: middle;
		}

		svg {
			fill: var(--color-primary);
			transition: all $duration;
		}

		&.swiper-button-disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		&--prev {
			transform: rotate(90deg);
		}

		&--next {
			transform: rotate(-90deg);
		}
	}

	&-pagination {
		display: block;
		z-index: 2;
		margin-top: 40px;
		padding-left: 30px;
		padding-right: 30px;
		max-width: 100%;
		text-align: center;

		&.swiper-pagination-lock {
			display: none !important;
		}

		.swiper-pagination-bullet {
			margin: 0 7px 7px 0 !important;
			width: 7px;
			height: 7px;
			background: rgba(255, 252, 248, 0.77);
			opacity: 1;
			transition: all $duration;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background-color: #8c8a86;
			}
		}

		.swiper-pagination-bullet-active {
			background: #8c8a86;
			box-shadow: none;
		}
	}

	@include mobile {
		&-pagination {
			margin-top: 30px;
		}
	}
}

/*slider price*/
.slider-from-to {
	font-size: 14px;

	&__title {
		margin-right: em(17, 12);

		span {
			text-transform: uppercase;
		}
	}

	&__inputs {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__label {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 47%;
		font-weight: 500;

		&:last-child {
			margin-right: 0;
		}
	}

	&__text {
		margin-bottom: em(5, 12);
		font-weight: 500;
	}

	&__input {
		display: block;
		padding: 0 5px;
		height: 31px;
		width: 100%;
		background-color: #F4F4F4;
		text-align: center;
		color: #414554;
		border: none;
		outline: none;
		border-radius: 5px;
	}

	&__slider {
		margin: 20px 7px 7px;
		height: 2px !important;
		border: none !important;

		.ui-slider-range {
			background-color: #fff !important;
		}

		.ui-slider-handle {
			cursor: pointer !important;
			top: -0.5em !important;
			background-color: #fff !important;
			border: none !important;
			border-radius: 50% !important;
		}

		.ui-state-hover {
			background-color: #fff;
		}
	}

	@include mobile {
		
	}
}

/*number slider*/
.number-slider {
	$this: &;

	flex-shrink: 0;
	display: inline-flex;
	align-items: center;
	height: em(40, 18);
	font-size: 18px;
	overflow: hidden;

	&__input {
		display: block;
		width: em(40, 18);
		height: 100%;
		text-align: center;
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
		border: 1px solid #ADB3D1;
		border-left: none;
		border-right: none;
	}

	&__up,
	&__down {
		flex-shrink: 0;
		display: block;
		width: em(40, 18);
		height: 100%;
		line-height: normal;
		color: #fff;
		background-color: #ADB3D1;
		border: none;
		outline: none;
		transition: background-color $duration;

		&:hover {
			background-color: var(--color-primary);
		}
	}
}

/*v-modal*/
.v-modal {
	$this: &;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 3000;
	width: 100vw;
	height: 100vh;
	background: rgba(46, 46, 46, 0.58);
	opacity: 0;
	transition: opacity 0.25s;
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	transition: opacity $duration;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&__inner {
		margin: 1.75rem auto;
		padding: 0 20px;
		width: 100%;
		max-width: 570px;
		min-height: calc(100% - 3.5rem);
		overflow: auto;

		&--centered {
			display: flex;
			align-items: center;
		}
	}

	&__content {
		$this: &;

		position: relative;
		padding: 37px 38px 36px;
		width: 100%;
		background-color: #fff !important;
		background-repeat: no-repeat;
		border-radius: 10px;
		overflow: hidden;
	}

	&__close {
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 15px;
		width: 30px;
		height: 30px;
		overflow: hidden;
		z-index: 3;
		transition: opacity 0.25s;
		transition: all $duration;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: pr(22, 32);
			height: 1px;
			border-radius: 2px;
			background-color: #333;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__title-block {
		margin-bottom: 40px;
		text-align: left;
	}

	&__title {
		margin: 0;
		font-weight: 700;
		font-size: 24px;
	}

	&__bnr {
		display: block;
		margin-bottom: 20px;

		img {
			max-width: 100%;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__subtitle {
		margin-top: 1em;
		font: italic 20px 500 var(--fm-1);
	}

	&#{&}--size {
		&_m {
			.v-modal__inner {
				max-width: 540px;
			}

			.v-modal__content {
				padding: 44px 32px 48px;
			}

			.v-modal__bnr {
				margin: -44px -32px 20px;
			}

			.application-f {
				&__inputs {
					> * {
						margin-bottom: 10px;
					}
				}

				&__btn {
					margin-top: 25px;
				}
			}

			@include mobile {
				.v-modal__content {
					padding: 36px 24px 40px;
				}

				.v-modal__bnr {
					margin: -36px -24px 20px;
				}
			}
		}
	}

	@include mobile {
		&__inner {
			max-width: 500px;
		}

		&__content {
			padding: 35px 25px 30px;
		}

		&__title-block {
			margin-bottom: 35px;
			text-align: center;
		}

		&__title {
			font-size: 20px;
		}
	}
}

.js-incomplete {
	$this: &;

	&-item {
		opacity: 1;
		transition: opacity 0.5s linear;

		&--hide {
			width: 0 !important;
			height: 0 !important;
			margin: 0 !important;
			padding: 0 !important;
			opacity: 0 !important;
			visibility: hidden !important;
		}
	}

	&--disabled {

		[incomplete-toggle],
		#{$this}-toggle {
			display: none;
		}
	}
}

#jGrowl {
	font-family: var(--fm-1);

	.ms2-message-success {
		background-color: var(--color-primary) !important;
	}
}