:root {
	--columns: #{$grid-columns};
	--air-x: 30px;
	--air-y: 20px;
}

@mixin row-flex {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 calc(var(--air-y) * -1) calc(var(--air-x) * -1);
}

@mixin col {
	margin: 0 0 var(--air-y) var(--air-x);
	word-wrap: break-word;
}

@mixin size($n, $afterText: "") {
	$after_text: "";

	width: calc(100% / var(--columns) * #{$n} - var(--air-x)) #{$afterText};
}

.b-col {
	@include col();
}

.b-row,
[class*="row-cols"] {
	--air-x: 30px;
	--air-y: 30px;

	@include row-flex;

	& > * {
		@include col;
	}
}

@for $i from 1 through $grid-columns {
	.row-cols-#{$i} {
		& > * {
			width: calc(100% / var(--columns) * #{$grid-columns/$i} - var(--air-x));
		}
	}

	.b-col-#{$i} {
		width: calc(100% / #{$grid-columns} * #{$i} - var(--air-x));
	}
}

@for $i from 0 through 60 {
	@if $i % 5 == 0 {
		.gx-#{$i},
		.g-#{$i} {
			--air-x: #{$i}px !important;
		}

		.gy-#{$i},
		.g-#{$i} {
			--air-y: #{$i}px !important;
		}
	}
}

@each $break, $value_break in $breakpoints {
	@media (max-width: $value_break) {
		@for $i from 0 through 60 {
			@if $i % 5 == 0 {
				.gx-#{$break}-#{$i},
				.g-#{$break}-#{$i} {
					--air-x: #{$i}px !important;
				}

				.gy-#{$break}-#{$i},
				.g-#{$break}-#{$i} {
					--air-y: #{$i}px !important;
				}
			}
		}

		@for $i from 1 through $grid-columns {
			.row-cols-#{$break}-#{$i} {
				& > * {
					width: calc(100% / #{$grid-columns} * #{$grid-columns/$i} - var(--air-x));
				}
			}

			.b-col-#{$break}-#{$i} {
				width: calc(100% / #{$grid-columns} * #{$i} - var(--air-x));
			}
		}
	}
}
