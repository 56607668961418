/*~~~~~~~~~~~~~~~~~~~~~~~~~ SETTINGS ~~~~~~~~~~~~~~~~~~~~~~~~~*/
.aic {
	align-items: center;
}

$utilities: (
	"display": (
		property: display,
		class: d,
		values: none inline "inline-block" block "inline-flex" flex "inline-grid" grid,
	),
	"flex-wrap": (
		property: "flex-wrap",
		class: flex,
		values: wrap "nowrap",
	),
	"flex-direction": (
		property: flex-direction,
		responsive: false,
		class: flex,
		values: column "column-reverse" row row-reverse,
	),
	"align-items": (
		responsive: false,
		property: "align-items",
		values: (
			start: "flex-start",
			center: "center",
			end: "flex-end",
		),
	),
	"align-self": (
		responsive: false,
		property: "align-self",
		values: (
			start: "flex-start",
			center: "center",
			end: "flex-end",
		),
	),
	"justify-content": (
		responsive: false,
		property: "justify-content",
		values: (
			start: "flex-start",
			center: "center",
			end: "flex-end",
			around: "space-around",
			between: "space-between",
		),
	),
	"flex-shrink": (
		responsive: false,
		property: "flex-shrink",
		values: 0 1,
	),
	"flex-grow": (
		property: "flex-grow",
		values: 0 1,
	),
	"height": (
		property: "height",
		class: h,
		values: (
			100: "100%",
		),
	),
	"order": (
		property: order,
		values: (
			n2: -2,
			n1: -1,
			1: 1,
			2: 2,
			3: 3,
		),
	),
	"font-weight": (
		responsive: false,
		property: "font-weight",
		class: fw,
		values: 200 300 400 500 600 700 800,
	),
	"font-size": (
		property: "font-size",
		class: fz,
		values: (
			10: "10px",
			12: "12px",
			14: "14px",
			15: "15px",
			16: "16px",
			17: "17px",
			18: "18px",
			20: "20px",
			24: "24px",
			28: "28px",
		),
	),
	"line-height": (
		responsive: false,
		property: "line-height",
		class: lh,
		values: (
			1: 1,
			sm: 1.22,
			base: 1.5,
			lg: 2,
		),
	),
	"text-decoration": (
		responsive: false,
		property: "text-decoration",
		values: (
			none: none,
			through: "line-through",
			underline: underline,
		),
	),
	"text-transform": (
		responsive: false,
		property: "text-transform",
		values: uppercase lowercase none,
	),
	"white-space": (
		responsive: false,
		property: "white-space",
		class: text,
		values: nowrap wrap,
	),
	"text-align": (
		property: "text-align",
		responsive: false,
		class: text,
		values: (
			start: left,
			center: center,
			right: right,
		),
	),
	"color": (
		property: "color",
		responsive: false,
		class: color,
		values: (
			primary: var(--color-primary),
			orange: var(--color-orange),
		),
	),
	"border-radius": (
		property: "border-radius",
		responsive: false,
		class: brs,
		values: (
			4: 4px,
			8: 8px,
			12: 12px,
			16: 16px,
			20: 20px,
			24: 24px,
			28: 28px,
			32: 32px,
			50p: 50%,
		),
	),
);

@each $prop, $unit in $utilities {
	$property: map-get($unit, property);
	$values: map-get($unit, values);
	$responsive: true;
	$name: $prop;
	$is_important: true;
	$after_value: "!important";

	@if map-has-key($unit, responsive) {
		$responsive: map-get($unit, responsive);
	}

	@if map-has-key($unit, class) {
		$name: map-get($unit, class);
	}

	@if map-has-key($unit, enable-important-utilities) {
		$is_important: map-get($unit, enable-important-utilities);
	}

	@if $is_important != true {
		$after_value: "";
	}

	@if type-of($values) == "map" {
		@each $postfix, $val in $values {
			.#{$name}-#{$postfix} {
				#{$property}: #{$val}#{$after_value};
			}
		}

		@if $responsive == true {
			@each $break, $value_break in $breakpoints {
				@media (max-width: $value_break) {
					@each $postfix, $val in $values {
						.#{$name}-#{$break}-#{$postfix} {
							#{$property}: #{$val}#{$after_value};
						}
					}
				}
			}
		}
	} @else if type-of($values) == "list" or "string" {
		@each $postfix in $values {
			.#{$name}-#{$postfix} {
				#{$property}: #{$postfix}#{$after_value};
			}
		}

		@if $responsive == true {
			@each $break, $value_break in $breakpoints {
				@media (max-width: $value_break) {
					@each $postfix in $values {
						.#{$name}-#{$break}-#{$postfix} {
							#{$property}: #{$postfix}#{$after_value};
						}
					}
				}
			}
		}
	}
}
