/*==================== ATOMS ====================*/
.angle-down {
	position: relative;
	display: inline-block;
	width: 7px;
	height: 7px;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		border: 1.5px solid currentColor;
		border-top: none;
		border-right: none;
		border-radius: 1px;
		transform: rotate(-45deg);
	}
}

/* badge */
.badge {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: em(8, 18) em(37, 18);
	min-height: 50px;
	color: #fff;
	text-align: center;
	background-color: var(--color-succes);
	border-radius: 3px;
	vertical-align: middle;

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}

	@include mobile {
		padding: em(6, 14) em(15, 14) em(5, 14);
		min-height: 38px;
	}
}

/* b checkbox */
.b-checkbox {
	$this: &;

	cursor: pointer;
	display: inline-flex;
	user-select: none;
	line-height: 1.22;

	input {
		position: absolute;
		appearance: none;
		opacity: 0;
		pointer-events: none;
	}

	&.active {
		#{$this}__custom::before {
			opacity: 1;
		}

		#{$this}__content {
			color: var(--color-text) !important;
		}
	}

	input:checked ~ #{$this}__custom::before,
	&.checked #{$this}__custom::before {
		opacity: 1;
	}

	&__custom {
		flex-shrink: 0;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-top: -0.1em;
		margin-right: em(14, 12);
		width: 15px;
		height: 15px;
		border: 1px solid var(--color-primary);
		border-radius: 1px;
		overflow: hidden;
		transition: all $duration;

		&::before {
			content: "\2713";
			position: absolute;
			left: 50%;
			top: 50%;
			display: block;
			font-weight: 600;
			font-size: 0.9em;
			opacity: 0;
			background-color: transparent;
			transition: background-color 0.2s;
			transform: translate(-50%, -50%);
		}
	}

	&__content {
		display: inline-block;
		margin-top: -0.1em;
	}

	&--text {
		#{$this}__custom {
			display: none;
		}

		#{$this}__custom {
			display: none;
		}

		input:checked ~ #{$this}__content {
			text-decoration: underline;
			font-weight: 600;
			color: var(--color-succes);
		}
	}

	&--disabled {
		pointer-events: none;

		#{$this}__custom {
			background-color: #eeeeee;
		}
	}
}

/*b-input*/
.b-input {
	$this: &;

	--air: 0 0 19px;
	--pl: 20px;
	--pr: 20px;
	--pdT: 15px var(--pl) 16px;
	--h: 45px;
	--fz: 14px;

	position: relative;
	display: inline-flex;
	justify-content: space-between;
	margin: var(--air);

	&:last-child {
		margin-bottom: 0;
	}

	&__input {
		position: relative;
		width: 100%;

		input,
		textarea {
			width: 100%;
			font-size: var(--fz);
			color: #414141;
			background-color: #F6F7FA;
			border: none;
			border-radius: 5px;
			resize: none;
			transition: all $duration;

			&:focus,
			&:active {
				outline: none;
			}
		}

		input {
			padding: 0 var(--pr) 0 var(--pl);
			height: var(--h);
		}

		textarea {
			padding: var(--pdT);
			min-height: 80px;
		}
	}

	input:required ~ &__placeholder {
		&::after {
			content: "\00a0 *";
			font-size: 1.2em;
			color: var(--color-red);
		}
	}

	input:focus ~ &__placeholder {
		opacity: 0.4;
	}

	&__placeholder {
		pointer-events: none;
		position: absolute;
		top: 50%;
		display: none;
		left: var(--pl);
		right: var(--pr);
		font-size: var(--fz);
		color: #414141;
		text-align: left;
		transform: translateY(-50%);
		transition: all $duration;

	 	@at-root input:placeholder-shown ~ .b-input__placeholder {
			display: block;
		}
	}

	@include mobile {
		--air: 0 0 15px;
	}
}

.b-radio {
	$this: &;

	cursor: pointer;
	display: inline-flex;
	align-items: center;
	user-select: none;
	line-height: 1.22;

	&__input {
		position: absolute;
		appearance: none;
		opacity: 0;
		pointer-events: none;
	}

	&.active {
		#{$this}__custom::before {
			opacity: 1;
		}

		#{$this}__content {
			color: var(--color-text) !important;
		}
	}

	&__input:checked ~ #{$this}__custom::before,
	&.checked #{$this}__custom::before {
		background-color: var(--color-primary);
	}

	&__custom {
		flex-shrink: 0;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-top: -0.1em;
		margin-right: em(9, 16);
		width: 16px;
		height: 16px;
		border: 1px solid var(--color-primary);
		border-radius: 50%;
		overflow: hidden;
		transition: all $duration;

		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			display: block;
			width: pr(8, 14);
			height: pr(8, 14);
			background-color: transparent;
			border-radius: 50%;
			transition: background-color 0.2s;
			transform: translate(-50%, -50%);
		}
	}

	&__content {
		display: inline-block;
		line-height: 1.2;
	}

	&--text {
		#{$this}__custom {
			display: none;
		}

		#{$this}__custom {
			display: none;
		}

		input:checked ~ #{$this}__content {
			text-decoration: underline;
			font-weight: 600;
			color: var(--color-succes);
		}
	}

	&--disabled {
		pointer-events: none;

		#{$this}__custom {
			background-color: #eeeeee;
		}
	}
}

.b-checkbox {
	$this: &;

	cursor: pointer;
	display: inline-flex;
	user-select: none;
	line-height: 1.22;

	&__input {
		position: absolute;
		appearance: none;
		opacity: 0;
		pointer-events: none;
	}

	&.active {
		#{$this}__custom::before {
			opacity: 1;
		}

		#{$this}__content {
			color: var(--color-text) !important;
		}
	}

	&__input:checked ~ #{$this}__custom::before,
	&.checked #{$this}__custom::before {
		opacity: 1;
	}

	&__custom {
		flex-shrink: 0;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-top: -0.1em;
		margin-right: em(6, 14);
		width: 16px;
		height: 16px;
		border: 1px solid var(--color-secondary);
		border-radius: 3px;
		overflow: hidden;
		transition: all $duration;

		&::before {
			content: "\2713";
			position: absolute;
			left: 50%;
			top: 55%;
			display: block;
			opacity: 0;
			color: var(--color-secondary);
			background-color: transparent;
			transition: background-color 0.2s;
			transform: translate(-50%, -50%);
		}
	}

	&__content {
		display: inline-block;
		line-height: 1.2;
	}

	&--text {
		#{$this}__custom {
			display: none;
		}

		#{$this}__custom {
			display: none;
		}

		input:checked ~ #{$this}__content {
			text-decoration: underline;
			font-weight: 600;
			color: var(--color-succes);
		}
	}

	&--disabled {
		pointer-events: none;

		#{$this}__custom {
			background-color: #eeeeee;
		}
	}
}

.b-select {
	display: inline-block;
	width: 263px;
	height: 35px;
	background-color: var(--color-bg-card);
	background-image: url(./../../img/icons/ui/all/arrow-select.svg);
	background-repeat: no-repeat;
	background-position: right 14px center;
	overflow: hidden;
	outline: 1px solid #E0E0E0;
	border-radius: 30px;

	&::-ms-expand {
		display: none;
	}

	select {
		cursor: pointer;
		appearance: none;
		flex: 1;
		display: block;
		padding: 0 29px 0 20px;
		height: 100%;
		width: 100%;
		font: 500 14px/1.18 var(--fm-1);
		color: #aeaeae;
		border: none;
		outline: none;
		text-overflow: ellipsis;
		background-color: transparent;

		option {
			font-size: 1.2em;
		}
	}
}

/* close */
.close {
	cursor: pointer;
	position: relative;
	width: 25px;
	height: 25px;
	z-index: 3;
	border-radius: 50%;
	transition: opacity 0.25s;
	transition: all $duration;

	&:hover {
		&::before,
		&::after {
			background-color: var(--color-red);
		}
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		width: pr(35, 25);
		height: 1.5px;
		border-radius: 1px;
		background-color: #757575;
		transition: all $duration;
	}

	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

/*firm info*/
.firm-info {
	$this: &;

	display: inline-flex;
	line-height: 1.22;
	color: inherit;
	transition: all $duration;

	@at-root a#{&} {
		@include reset-link;

		&:hover {
			color: var(--color-link);

			#{$this}__ic {
				fill: var(--color-link);
			}
		}
	}

	a {
		transition: all $duration;
		&:hover {
			text-decoration: none;
		}
	}

	&__ic {
		flex-shrink: 0;
		display: inline-block;
		margin-right: em(7, 12);
		max-width: em(14, 12);
		max-height: em(14, 12);
		fill: #676767;
		vertical-align: middle;
		transform: translateY(0.1em);
		transition: all $duration;

		&:not(:first-child) {
			margin-left: em(7, 12);
		}

		svg {
			transition: all $duration;
		}
	}

	&--email {
		font-weight: 700;
		color: var(--color-primary);

		#{$this}__ic {
			fill: var(--color-primary);
		}
	}
}

.fixed {
	position: fixed;
	z-index: 4;
	left: 0;
	top: 0;
	margin: 0 !important;
	width: 100%;
	will-change: transform;
}

/* meta */
.meta {
	&__list {
		--air-x: 38px;
		--ari-y: 20px;
		@include reset-list;
		@include row-flex;

		& > * {
			@include col;
		}
	}

	&__item {
		display: inline-block;
		line-height: 1.22;
		color: #989590;

		svg {
			display: inline-block;
			margin-right: em(7, 16);
			fill: currentColor;
			vertical-align: middle;
			transform: translateY(-0.1em);
		}
	}

	@include mobile {
		&__list {
			--air-x: 18px;
		}
	}
}

.note-metadata {
	display: flex;
	column-gap: 29px;
	&__item {
		display: flex;
		align-items: center;
		line-height: 1;
		font: 500 1em /#{(25/14)} var(--fm-1);
		color: #cecece;

		svg {
			margin: -#{em(2, 14)} #{em(12, 14)} 0 0;
			width: em(21, 14);
			height: em(21, 14);
			fill: currentColor;
		}
	}
}

/*hamburger*/
.hamburger {
	--color-tone: #333;
	$this: &;

	position: relative;
	width: 42px;
	height: 21px;
	background-color: transparent;
	outline: none;
	border: none;

	&.active {
		span {
			transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.15s;
			transform: rotate(90deg);
			background-color: transparent !important;
		}

		span::after {
			bottom: 0 !important;
			width: 100%;
			transition: bottom 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
			transform: rotate(45deg);
		}

		span::before {
			top: 0 !important;
			transition: top 75ms ease-out 0.1s, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
			transform: rotate(-45deg);
		}
	}

	span,
	span::before,
	span:after {
		position: absolute;
		right: 0;
		height: 2.5px;
		width: 100%;
		background-color: #fff;
		transition-timing-function: eafse;
		transition-duration: 0.15s;
		transition-property: transform;
		border-radius: 2px;
	}

	span {
		top: 50%;
		display: block;
		transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.15s, background-color $duration;
	}

	span::before,
	span::after {
		display: block;
		content: "";
	}

	span::before {
		top: -9px;
		transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, background-color $duration;
	}

	span::after {
		bottom: -9px;
		width: pr(37, 54);
		transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s,background-color $duration;
	}
}

.logo {
	display: inline-flex;
	max-width: 205px;

	img {
		display: block;
		max-width: 100%;
	}
}

.line {
	width: 100%;
	border-bottom: 0.5px solid #E0E0E0 
}

.search-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 40px;
	height: 40px;
	background-color: #fff;
	border-radius: 50%;
	border: none;
	outline: none;
	transition: all $duration;

	&:hover {
		background-color: var(--color-orange);

		svg {
			fill: #fff;
		}
	}
	
	> * {
		margin: auto;
		height: pr(15, 40);
		width: pr(15, 40);
	}

	svg {
		fill: var(--color-orange);
		transition: fill $duration;
	}
}

.sci-list {
	display: inline-flex;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: 10px;
	}
}

.sci {
	&__list {
		--air-x: 10px;
		--air-y: 10px;
		@include row-flex;
	}

	&__link {
		@include col;
	}
}

.sci-link {
	flex-shrink: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	transition: transform $duration;

	&:hover {
		transform: scale(1.1);
	}

	svg {
		max-width: 100%;
		max-height: 100%;
	}
}

/*sorting*/
.sorting {
	
	&__list {
		margin-bottom: -#{em(10, 14)};

		& > * {
			margin-right: em(11, 14);
			margin-bottom: em(10, 14);
		}

		a,
		span {
			display: inline-block;
		}

		a {
			text-decoration: none;

			&.active {
				color: var(--color-primary);

				&::before {
					content: "";
					display: inline-block;
					margin-right: em(5, 14);
					margin-bottom: -0.15em;
					width: em(8, 14);
					height: em(14, 14);
					background-image: url(./../../img/icons/ui/all/arrow-sort.svg);
					background-repeat: no-repeat;
					background-size: contain;
				}

				&[data-dir="asc"] {
					&::before {
						transform: scaleY(-1);
					}
				}
			}

			&:hover {
				color: var(--color-secondary);
			}
		}
	}

	&__title {
		font-weight: 600;
		color: #414554;
	}
}

/*msgr*/
.msgrs {
	$this: &;

	display: inline-flex;
	align-items: center;
	line-height: 1.22;

	&__link {
		@include reset-link;

		flex-shrink: 0;
		display: block;
		margin-left: 10px;
		white-space: nowrap;
		color: inherit;
		fill: var(--color-primary);
		fill-opacity: 1;
		transition: transform $duration;

		&:not(#{$this}__link--phone):hover {
			transform: scale(1.1);
		}

		&:first-child {
			margin-left: 0;
		}

		svg {
			display: block;
			width: 30px;
			height: 30px;
		}

		&--phone {
			position: relative;
			flex-grow: 1;
			flex-shrink: 1;
			margin-left: 10px;
			align-self: center;
			height: auto !important;
			width: auto !important;
			transition: all $duration;

			&:hover {
				text-decoration: none;
				&::before {
					opacity: 1;
				}
			}

			&::before {
				content: "";
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 1px;
				opacity: 0;
				background-color: var(--color-primary);
				transition: all 0.3s;
			}

			b {
				font-weight: 700;
				font-size: em(24, 16);
				color: var(--color-primary);
			}
		}
	}

	@include mobile {
		&__link {
			margin-left: 7px;

			svg {
				width: 24px;
				height: 24px;
			}

			&--phone {
				margin-top: -0.5em;
			}
		}
	}
}

/*productLabel*/
.label-prod {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	font-weight: 700;
	font-size: 12px;
	line-height: (22/18);
	color: #fff;
	border-radius: 50%;
	overflow: hidden;

	&--img {
		background-color: transparent;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.list {
	counter-reset: li;
	
	&#{&}--style {
		&_decimal-bg {
			li {
				list-style-type: none !important;
				margin-bottom: em(20, 14);
				padding-left: 51px;

				&::before {
					counter-increment: li;
					content: counter(li, decimal);
					align-self: flex-start;
					flex-shrink: 0;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-left: -51px;
					margin-right: 19px;
					min-height: em(32, 20);
					min-width: em(32, 20);
					font-weight: 700;
					font-size: 20px;
					line-height: 28px;
					color: #fff;
					text-align: center;
					background-color: var(--color-orange);
					border-radius: 50%;
				}
			}
		}
	}
}

.plate-prod {
	padding: em(8, 14) 10px;
	max-width: 150px;
	width: 100%;
	text-transform: uppercase;
	font-weight: 600;
	color: #fff;
	text-align: center;
	background-color: var(--color-danger);
	border-radius: 40px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* record control */
.record-control {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		margin-bottom: 9px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__counter {
		font-weight: 300;
		font-weight: 14;
	}

	&__more {
		min-width: em(250, 16);
	}

	@include mobile {
		& > * {
			margin-bottom: 15px;
		}
	}
}

.response-img {
	@include table {
		margin: 0 auto;
		max-width: 500px;
	}
}

.hidden {
	display: none !important;
}

.instalment {
	--air-x: 20px;
	
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	row-gap: 1em;

	& > * {
		margin-right: var(--air-x);

		&:last-child {
			margin-right: 0;
		}
	}

	&__title {
		font-weight: 500;
		color: #757575;
	}

	&__price {
		font: 700 #{em(16, 14)} var(--fm-2);
		color: var(--color-orange) !important;
	}

	&__text {
		font-weight: 500;
		color: #818181;
	}
}

